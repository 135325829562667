.codeblock{
    height: 100vh;
    overflow: scroll;
    width: 100%;
    background-color: #30353e;
}
.contentContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    gap:40px
}

.cBlockTitle{
    font-size: 60px;
    color: White;
    margin-top: 35px;
    text-decoration: underline;

}
.roleTitle{
    color: #ec3360;
    font-size: 17px;
    font-weight: bold;
}

.codeTask{
    color:white;
    font-size: 20px;

}

.editorFrame{
    border: 1px solid white;
    margin-top: 50px;
}

.editorFrame:focus{

    border: 1px solid #ec3360;
}



.editCodeContainer{
    width:50%;
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    gap:30px;
    padding: 10px 0 0 10px;

}
.editorTitle{
    font-size: 30px;
    color:white;

}

.codeAreaContainer{
    width:50%;
    height: 35%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:30px;
    
}
.codeAreaFrame{
    width:100%;
    height: 100%;
    border: solid 1px white;
    background-color: #303746;

}
.editCode{
    width: 100%;
    height: 300px;
    color:white;
    background-color: #303746;
    font-size: 18px;

}

.codeArea{
    color: white;
    white-space: pre;

}
.codeAreaTitle{
    font-size: 30px;
    color:white;
}

.successContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:15px;
}

.sucessNote{
    color: white;
    font-size: 20px;
}





@media screen and (max-width:800px) {

    .codeAreaContainer{
        width:70%;
    }
    .editCodeContainer{
        width: 70%;
    }

    
}



@media screen and (max-width:400px) {

    .codeAreaContainer{
        width:90%;
    }
    .editCodeContainer{
        width: 90%;
    }

    .cBlockTitle{
        font-size: 50px;
    }
    .editorTitle{
        font-size: 27px;
    }

    .codeAreaTitle{
        font-size: 27px;
    }



    
}